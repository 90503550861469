import React, { useState } from 'react';

import { FormattedMessage } from 'gatsby-plugin-react-intl';

import ArrowTip from '../../Svgs/ArrowTip/ArrowTip';

import * as css from './QaCard.module.scss';

export type QaCardProps = {
  questionId: string;
  answerId: string;
};

const QaCard = ({ questionId, answerId }: QaCardProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    console.log('clicked');
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={isOpen ? `${css.container} ${css.open}` : css.container}
      onClick={handleClick}
    >
      <div className={css.header}>
        <div className={css.question}>
          <FormattedMessage
            id={questionId}
            defaultMessage={questionId}
            description="Q&A question"
          />
        </div>
        <div className={css.arrow}>
          <ArrowTip
            height={16}
            direction={isOpen ? 'top' : 'bottom'}
            variant={isOpen ? 'orange' : 'secondary'}
          />
        </div>
      </div>
      <div className={css.body}>
        <p>
          &quot;
          <FormattedMessage
            id={answerId}
            defaultMessage={answerId}
            description="Q&A answer"
          />
          &quot;
        </p>
      </div>
    </div>
  );
};

export default QaCard;
