import React from 'react';

import { useIntl } from 'gatsby-plugin-react-intl';
import { Helmet } from 'react-helmet';

import JsonLd from './JsonLd';

export type HomeSEOProps = {
  imageUrl: string;
};

const HomeSEO = ({ imageUrl }: HomeSEOProps) => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'home-seo.title',
  });
  const description = intl.formatMessage({
    id: 'home-seo.description',
  });
  return (
    <>
      <Helmet
        defaultTitle={title}
        lang={intl.formatMessage({
          id: 'seo.lang',
        })}
        description={description}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: `og:url`,
            content: `https://www.autocut.fr/${intl.locale}`,
          },
          {
            property: `og:image`,
            content: `https://www.autocut.fr${imageUrl}`,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:image`,
            content: `https://www.autocut.fr${imageUrl}`,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            name: 'twitter:site',
            content: '@ToolPad_fr',
          },
          {
            name: 'twitter:creator',
            content: '@ToolPad_fr',
          },
        ]}
      >
        <html
          lang={intl.formatMessage({
            id: 'seo.lang',
          })}
        />
      </Helmet>
      <JsonLd productImageUrl={`https://www.autocut.fr${imageUrl}`} />
    </>
  );
};

export default HomeSEO;
