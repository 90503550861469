import React from 'react';

import { FormattedMessage } from 'gatsby-plugin-react-intl';

import * as css from './ArrowText.module.scss';

export type ArrowTextProps = {
  textId: string;
  arrowDirection: 'top' | 'bottom';
  arrowPosition: 'left' | 'right';
};

const ArrowText = ({
  textId,
  arrowDirection,
  arrowPosition,
}: ArrowTextProps) => {
  return (
    <div
      className={`${css.container} ${css[arrowDirection]} ${css[arrowPosition]}`}
    >
      <FormattedMessage
        id={textId}
        defaultMessage={textId}
        description="Welcome header on app main page"
      />
    </div>
  );
};

export default ArrowText;
