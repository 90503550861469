import React, { useEffect, useState } from 'react';

import {
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'gatsby-plugin-react-intl';

import ArrowTip from '../../Svgs/ArrowTip/ArrowTip';

import * as css from './PriceCard.module.scss';

export type PriceCardProps = {
  link: string;
  titleId: string;
  subtitleId: string;
  price: number;
  previousPrice: null | number;
  reduction: null | string;
  duration: string;
  variant: 'primary' | 'secondary';
};

const PriceCard = ({
  link,
  titleId,
  subtitleId,
  price,
  previousPrice,
  reduction,
  duration,
  variant,
}: PriceCardProps) => {
  const [currency, setCurrency] = useState<'EUR' | 'USD'>('EUR');

  const intl = useIntl();

  useEffect(() => {
    setCurrency(intl.locale == 'en' ? 'USD' : 'EUR');
  });

  const handleClick = () => {
    window.location.href = link;
  };

  return (
    <div
      onClick={handleClick}
      className={
        variant == 'primary' ? `${css.container} ${css.primary}` : css.container
      }
    >
      <div className={css.textContainer}>
        <div className={css.header}>
          <div className={css.title}>
            <FormattedMessage
              id={titleId}
              defaultMessage={titleId}
              description="Price card title"
            />
          </div>
          <div className={css.subtitle}>
            <p>
              <FormattedMessage
                id={subtitleId}
                defaultMessage={subtitleId}
                description="Price card subtitle"
              />
            </p>
          </div>
        </div>
        <div className={css.priceContainer}>
          {previousPrice && (
            <div className={css.previousPrice}>
              <span className={css.price}>
                <FormattedNumber
                  value={previousPrice}
                  style={'currency'}
                  currency={currency}
                  minimumFractionDigits={0}
                />
              </span>
              <span className={css.precentage}>{reduction}</span>
            </div>
          )}
          <span className={css.amount}>
            <FormattedNumber
              value={price}
              style={'currency'}
              currency={currency}
              minimumFractionDigits={price < 10 ? 2 : price < 100 ? 1 : 0}
            />
          </span>
          <span className={css.duration}>
            /
            <FormattedMessage
              id={duration}
              defaultMessage={duration}
              description="Price card duration"
            />
          </span>
        </div>
      </div>
      <div className={css.arrowContainer}>
        <ArrowTip height={23} variant={variant} direction="right" />
      </div>
    </div>
  );
};

export default PriceCard;
