import React, { useEffect, useState } from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import AnimationVideo from '../../assets/Animation1.mp4';
import InfoCard from '../../components/Cards/InfoCard/InfoCard';
import DotCube from '../../components/Decorations/DotCube/DotCube';
import LineCube from '../../components/Decorations/LineCube/LineCube';
import SunShines from '../../components/Decorations/SunShines/SunShines';
import SecondaryTitle from '../../components/Title/SecondaryTitle/SecondaryTitle';
import { breakpoints } from '../../constants/breakpoints';
import { useDimension } from '../../hooks/useDimension';

import * as css from './IndexInfos.module.scss';

const IndexInfos = () => {
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const dimensions = useDimension();

  useEffect(() => {
    setIsTablet(dimensions.width <= breakpoints.tablet.max);
  }, [dimensions]);

  return (
    <div>
      <div className="container-flex">
        <div className="container">
          <SecondaryTitle
            size="large"
            titleId="index.infos.secondaryTitle.title"
            subtitleId="index.infos.secondaryTitle.subtitle"
          />
          <div className={css.infosContainer}>
            <InfoCard
              titleId="index.infos.cards.automatic.title"
              textId="index.infos.cards.automatic.text"
              variant={'left'}
            >
              <div className={css.illustrationContainer}>
                <div className={css.illustration}>
                  <StaticImage
                    src="../../assets/app1.png"
                    alt="AutoCut dashboard"
                    placeholder="blurred"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className={css.background} style={{ bottom: '16px' }}>
                  <div className={css.lineCube}>
                    <LineCube />
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 741 107"
                  >
                    <ellipse
                      cx="370.5"
                      cy="53.5"
                      fill="#1463f3"
                      rx="370.5"
                      ry="53.5"
                    />
                  </svg>
                </div>
              </div>
            </InfoCard>
            <InfoCard
              titleId="index.infos.cards.fast.title"
              textId="index.infos.cards.fast.text"
              variant={'right'}
            >
              <div className={css.illustrationContainer}>
                <div className={css.illustration} style={{ filter: 'none' }}>
                  <StaticImage
                    src="../../assets/app2.png"
                    alt="AutoCut Dashboard"
                    placeholder="blurred"
                  />
                </div>
              </div>
            </InfoCard>
            <InfoCard
              titleId="index.infos.cards.tutorial.title"
              textId="index.infos.cards.tutorial.text"
              variant={'left'}
            >
              <div className={css.illustrationContainer}>
                <div className={css.illustration}>
                  <StaticImage
                    src="../../assets/app2.png"
                    alt="AutoCut Dashboard"
                    placeholder="blurred"
                  />
                  {!isTablet ? (
                    <div className={css.sunShines}>
                      <SunShines />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={css.background} style={{ bottom: '40px' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 754 291"
                  >
                    <path
                      fill="#1463f3"
                      fillRule="evenodd"
                      d="M754 291C754 130 585 0 377 0S0 130 0 291h754Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </InfoCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexInfos;
