import React, { useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';

import * as css from './InfoCard.module.scss';

export type InfoCardType = {
  titleId: string;
  textId: string;
  children: JSX.Element;
  variant: 'left' | 'right';
};

const InfoCard = ({ titleId, textId, children, variant }: InfoCardType) => {
  //Parsing title to add orange accents
  //TODO: Implement intl in the process
  const [titleText, setTitleText] = useState<string>('');
  const intl = useIntl();
  useEffect(() => {
    //TODO: Replace titleId by text retreived by Intl
    let parsedTitle = intl.formatMessage({
      id: titleId,
      defaultMessage: titleId,
    });
    const matches = parsedTitle.match(/\*\*(.*?)\*\*/g);
    if (matches) {
      for (const match of matches) {
        parsedTitle = parsedTitle.replaceAll(
          match,
          `<span class='text-orange'>${match.replaceAll('**', '')}</span>`
        );
      }
    }

    setTitleText(parsedTitle);
  }, []);
  return (
    <div
      className={
        variant == 'right' ? `${css.container} ${css.reverse}` : css.container
      }
    >
      <div className={css.texts}>
        <div className={css.title}>
          <p dangerouslySetInnerHTML={{ __html: titleText }}></p>
        </div>
        <p className={css.subtitle}>
          <FormattedMessage
            id={textId}
            defaultMessage={textId}
            description="Card text"
          />
        </p>
      </div>
      <div className={css.illustration}>{children}</div>
    </div>
  );
};

export default InfoCard;
