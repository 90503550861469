import React, { useEffect, useState } from 'react';

import axios from 'axios';

import CharacteristicsCard from '../../components/Cards/CharacteristicsCard/CharacteristicsCard';
import PriceCard from '../../components/Cards/PriceCard/PriceCard';
import SecondaryTitle from '../../components/Title/SecondaryTitle/SecondaryTitle';
import type CheckCouponType from '../../types/CheckCouponType';
import { CURRENT_ENV } from '../../utils/currentEnv';

import * as css from './IndexPricing.module.scss';

const IndexPricing = () => {
  const [affiliate, setAffiliate] = useState<null | string>(null);
  const [coupon, setCoupon] = useState<null | string>(null);
  const [paramsToRedirect, setParamsToRedirect] = useState<string>('');

  const [monthlyPrice, setMonthlyPrice] = useState<number>(39.9);
  const [previousMonthlyPrice, setPreviousMonthlyPrice] = useState<
    number | null
  >(null);
  const [monthlyReduction, setMonthlyReduction] = useState<null | string>(null);

  const [yearlyPrice, setYearlyPrice] = useState<number>(390.9);
  const [previousYearlyPrice, setPreviousYearlyPrice] = useState<number | null>(
    null
  );
  const [yearlyReduction, setYearlyReduction] = useState<null | string>(null);

  const [lifePrice, setLifePrice] = useState<number>(199);
  const [previousLifePrice, setPreviousLifePrice] = useState<number | null>(
    null
  );
  const [lifeReduction, setLifeReduction] = useState<null | string>(null);

  useEffect(() => {
    const query = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(query);
    if (urlParams.has('affiliate')) {
      setAffiliate(urlParams.get('affiliate'));
    }
    if (urlParams.has('coupon')) {
      setCoupon(urlParams.get('coupon'));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const resCoupons: { data: CheckCouponType } = await axios.get(
        (CURRENT_ENV === 'development'
          ? 'http://localhost:3000'
          : 'https://app.autocut.fr') + '/api/stripe/check-coupon',
        {
          params: { coupon: coupon, affiliate: affiliate },
        }
      );

      if (!resCoupons || !resCoupons.data || !resCoupons.data.valid) {
        return;
      }

      if (resCoupons.data.month) {
        setPreviousMonthlyPrice(monthlyPrice);
        setMonthlyPrice(
          monthlyPrice -
            (resCoupons.data.month.fixed_amount +
              monthlyPrice * resCoupons.data.month.percentage)
        );

        const percentageReduction =
          resCoupons.data.month.percentage != 0
            ? `-${resCoupons.data.month.percentage}%`
            : null;
        const fixedAmountReduction =
          resCoupons.data.month.fixed_amount != 0
            ? `-${resCoupons.data.month.fixed_amount}€`
            : null;
        setMonthlyReduction(
          `${percentageReduction ? percentageReduction : ''} ${
            fixedAmountReduction ? fixedAmountReduction : ''
          }`
        );
      }
      if (resCoupons.data.year) {
        setPreviousYearlyPrice(yearlyPrice);
        setYearlyPrice(
          yearlyPrice -
            (resCoupons.data.year.fixed_amount +
              yearlyPrice * resCoupons.data.year.percentage)
        );

        const percentageReduction =
          resCoupons.data.year.percentage != 0
            ? `-${resCoupons.data.year.percentage}%`
            : null;
        const fixedAmountReduction =
          resCoupons.data.year.fixed_amount != 0
            ? `-${resCoupons.data.year.fixed_amount}€`
            : null;
        setYearlyReduction(
          `${percentageReduction ? percentageReduction : ''} ${
            fixedAmountReduction ? fixedAmountReduction : ''
          }`
        );
      }
      if (resCoupons.data.life) {
        setPreviousLifePrice(lifePrice);
        setLifePrice(
          lifePrice -
            (resCoupons.data.life.fixed_amount +
              lifePrice * resCoupons.data.life.percentage)
        );

        const percentageReduction =
          resCoupons.data.life.percentage != 0
            ? `-${resCoupons.data.life.percentage}%`
            : null;
        const fixedAmountReduction =
          resCoupons.data.life.fixed_amount != 0
            ? `-${resCoupons.data.life.fixed_amount}€`
            : null;
        setLifeReduction(
          `${percentageReduction ? percentageReduction : ''} ${
            fixedAmountReduction ? fixedAmountReduction : ''
          }`
        );
      }
    };

    if (!affiliate && !coupon) return;

    const baseParams = '?';
    const params = [];
    if (affiliate) {
      params.push(`affiliate=${affiliate}`);
    }
    if (coupon) {
      params.push(`coupon=${coupon}`);
    }
    setParamsToRedirect(`${baseParams}${params.join('&')}`);

    fetchData().catch(console.error);
  }, [affiliate, coupon]);
  return (
    <div>
      <div className="container-flex">
        <div className={css.container}>
          <SecondaryTitle
            size="large"
            titleId="index.pricing.secondaryTitle.title"
            subtitleId="index.pricing.secondaryTitle.subtitle"
            addSunshines={true}
          />
          <div className={css.pricingContainer}>
            <div className={css.characteristicsCard}>
              <CharacteristicsCard />
            </div>
            <div className={css.priceCardsContainer}>
              <PriceCard
                link={'/download'}
                titleId="index.pricing.pricesCards.month.title"
                subtitleId="index.pricing.pricesCards.unsubscribe"
                price={monthlyPrice}
                previousPrice={previousMonthlyPrice}
                reduction={monthlyReduction}
                duration="index.pricing.pricesCards.month.per"
                variant="primary"
              />
              <PriceCard
                link={'/download'}
                titleId="index.pricing.pricesCards.year.title"
                subtitleId="index.pricing.pricesCards.unsubscribe"
                price={yearlyPrice}
                previousPrice={previousYearlyPrice}
                reduction={yearlyReduction}
                duration="index.pricing.pricesCards.year.per"
                variant="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPricing;
