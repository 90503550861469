// extracted by mini-css-extract-plugin
export var body = "CharacteristicsCard-module--body--17182";
export var bottom = "CharacteristicsCard-module--bottom--d043b";
export var container = "CharacteristicsCard-module--container--e6810";
export var containerFlex = "CharacteristicsCard-module--container-flex--263fe";
export var header = "CharacteristicsCard-module--header--a2358";
export var line = "CharacteristicsCard-module--line--9f7f0";
export var text = "CharacteristicsCard-module--text--87294";
export var textOrange = "CharacteristicsCard-module--text-orange--7253c";
export var title = "CharacteristicsCard-module--title--5c5fe";
export var top = "CharacteristicsCard-module--top--f42bb";