import '../styles/index.scss';

import React from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import HomeSEO from '../components/Seo/HomeSEO';
import IndexBlog from '../sections/IndexBlog/IndexBlog';
import IndexFaq from '../sections/IndexFaq/IndexFaq';
import IndexGradientBanner from '../sections/IndexGradientBanner/IndexGradientBanner';
import IndexInfos from '../sections/IndexInfos/IndexInfos';
import IndexMain from '../sections/IndexMain/IndexMain';
import IndexPricing from '../sections/IndexPricing/IndexPricing';
import IndexTestimonials from '../sections/IndexTestimonials/IndexTestimonials';

import * as css from './index.module.scss';

const IndexPage = ({ data }) => {
  const { allBlogPost, imageUrl } = data;

  const imageOg = imageUrl.edges[0]?.node.fluid.src;

  return (
    <Layout>
      <HomeSEO imageUrl={imageOg} />
      <div className={css.mainContainer}>
        <IndexMain />
      </div>
      <div className={css.infoContainer} id="product">
        <IndexInfos />
      </div>
      {/*<div className={css.testimonialsContainer} id="testimonials">
        <IndexTestimonials />
  </div>*/}
      <div className={css.pricingContainer} id="pricing">
        <IndexPricing />
      </div>
      <div className={css.faqContainer} id="faq">
        <IndexFaq />
      </div>
      <div className={css.blogContainer}>
        <IndexBlog blogPosts={allBlogPost} />
      </div>
      <div className={css.gradientBannerContainer}>
        <IndexGradientBanner />
      </div>
    </Layout>
  );
};
export default IndexPage;

export const query = graphql`
  query {
    allBlogPost: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          language
          title
          tags
          date
          slug
          metaDescription
          thumb {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    imageUrl: allImageSharp(
      filter: { fluid: { originalName: { eq: "App1.png" } } }
    ) {
      edges {
        node {
          id
          fluid {
            src
          }
        }
      }
    }
  }
`;
