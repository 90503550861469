import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

import * as css from './CharacteristicsCard.module.scss';

const CharacteristicsCard = () => {
  return (
    <div className={css.container}>
      <div className={css.top}>
        <div className={css.header}>
          <div className={css.title}>
            <FormattedMessage
              id="index.pricing.characteristicsCard.title"
              defaultMessage="Characteristics"
              description="Characteristics card title"
            />
          </div>
          <div className={css.subtitle}>
            <p>
              <FormattedMessage
                id="index.pricing.characteristicsCard.subtitle"
                defaultMessage="Lorem ipsum dolor sit amet. In numquam reprehenderit aut dolor rerumea."
                description="Characteristics card subtitle"
              />
            </p>
          </div>
        </div>
        <div className={css.body}>
          <div className={css.line}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 30 30"
              width="16px"
            >
              <rect width="30" height="30" fill="#1463f3" rx="15" />
              <path
                stroke="#F8FAFC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="m8 16 4 4 10-10"
              />
            </svg>
            <div className={css.text}>
              <FormattedMessage
                id="index.pricing.characteristicsCard.chars1"
                defaultMessage="You can unsubscribe at any time"
                description="Characteristics card characteristic"
              />
            </div>
          </div>
          <div className={css.line}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 30 30"
              width="16px"
            >
              <rect width="30" height="30" fill="#1463f3" rx="15" />
              <path
                stroke="#F8FAFC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                d="m8 16 4 4 10-10"
              />
            </svg>
            <div className={css.text}>
              <FormattedMessage
                id="index.pricing.characteristicsCard.chars2"
                defaultMessage="Secure payment by Paypal or Credit Card"
                description="Characteristics card characteristic"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={css.bottom}>
        <StaticImage
          src="../../../assets/payments/visa.png"
          alt="Visa Logo"
          placeholder="blurred"
          width={40}
        />
        <StaticImage
          src="../../../assets/payments/mastercard.png"
          alt="Mastercard Logo"
          placeholder="blurred"
          width={40}
        />
        <StaticImage
          src="../../../assets/payments/paypal.png"
          alt="Paypal Logo"
          placeholder="blurred"
          width={40}
        />
        <StaticImage
          src="../../../assets/payments/americanexpress.png"
          alt="American Express Logo"
          placeholder="blurred"
          width={40}
        />
      </div>
    </div>
  );
};

export default CharacteristicsCard;
