import React from 'react';

import GradientBanner from '../../components/Decorations/GradientBanner/GradientBanner';

const IndexGradientBanner = () => {
  return (
    <div className="container-flex">
      <div className="container">
        <GradientBanner />
      </div>
    </div>
  );
};

export default IndexGradientBanner;
