import React from 'react';

import Button from '../../Button/Button';
import SecondaryTitle from '../../Title/SecondaryTitle/SecondaryTitle';
import DotCube from '../DotCube/DotCube';

import * as css from './GradientBanner.module.scss';

const GradientBanner = () => {
  return (
    <div className={css.container}>
      <div className={css.text}>
        <SecondaryTitle
          size="large"
          titleId="gradientBanner.title"
          subtitleId="gradientBanner.subtitle"
        />
        <div className={css.buttons}>
          <Button
            link="download"
            titleId="gradientBanner.startButton.title"
            variant="primary"
          />
        </div>
      </div>
      <div className={css.dotCube}>
        <DotCube cubeSize={3} />
      </div>
      <div className={css.circlesOuterContainer}>
        <div className={css.circlesContainer}>
          <svg
            className={css.circleOne}
            width="472"
            height="472"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 629 629"
          >
            <circle
              cx="314.5"
              cy="314.5"
              r="311.5"
              stroke="#1463f3"
              strokeWidth="4"
            />
          </svg>
          <svg
            className={css.circleTwo}
            width="472"
            height="472"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 629 629"
          >
            <circle
              cx="314.5"
              cy="314.5"
              r="311.5"
              stroke="#1463f3"
              strokeWidth="4"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default GradientBanner;
