import React, { useEffect, useState } from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import AutocutVideo from '../../assets/Autocut-low.mp4';
import AutocutVideoWebM from '../../assets/Autocut-low.webm';
import HeroVideo from '../../assets/hero.mp4';
import Badge from '../../components/Badge/Badge';
import Button from '../../components/Button/Button';
import StatisticsCard from '../../components/Cards/StatisticsCard/StatisticsCard';
import ArrowText from '../../components/Decorations/ArrowText/ArrowText';
import DotCube from '../../components/Decorations/DotCube/DotCube';
import LineCube from '../../components/Decorations/LineCube/LineCube';
import MainTitle from '../../components/Title/MainTitle/MainTitle';
import { breakpoints } from '../../constants/breakpoints';
import { useDimension } from '../../hooks/useDimension';
import useScrollPosition from '../../hooks/useScrollPosition';
import { AutoCutPoster, PosterTimeline } from './Posters';

import * as css from './IndexMain.module.scss';

const IndexMain = () => {
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const dimensions = useDimension();

  const position = useScrollPosition();

  useEffect(() => {
    setIsTablet(dimensions.width <= breakpoints.tablet.max);
  }, [dimensions]);

  return (
    <>
      <div className={css.mainSection}>
        <div className={css.mainContainerFlex}>
          <div
            className={
              isTablet
                ? `${css.mainContainer} ${css.mainContainerMobile}`
                : `${css.mainContainer} ${css.mainContainerDesktop}`
            }
          >
            <MainTitle
              titleId="index.mainTitle.title"
              subtitleId="index.mainTitle.subtitle"
            >
              <div className={css.buttonsContainer}>
                <Button
                  link="/download"
                  titleId="index.mainTitle.buttonText"
                  variant="primary"
                />
              </div>
            </MainTitle>
            {!isTablet ? (
              <div className={css.arrowContainer}>
                <ArrowText
                  textId="index.arrow.text"
                  arrowDirection="top"
                  arrowPosition="left"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={css.mainSectionDecoration}>
          {!isTablet && (
            <>
              <div className={css.dotCube}>
                <DotCube cubeSize={3} />
              </div>
              <div className={css.lineCube}>
                <LineCube animate={true} />
              </div>
            </>
          )}
          <div className={css.dashContainer}>
            <div className={css.innerContainer}>
              <div className={css.videosContainer}>
                <video
                  autoPlay={true}
                  loop
                  muted
                  className={css.timeline}
                  poster={PosterTimeline}
                >
                  <source src={HeroVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className={css.gradient}></div>
      </div>
      {/*<div className={css.statisticsCards}>
        <StatisticsCard
          icon={
            <StaticImage
              src={'../../assets/users.svg'}
              alt="Users"
              placeholder="blurred"
              width={35}
            />
          }
          titleId="10 000+"
          subtitleId="index.statisticsCards.users.subtitle"
        />
        <StatisticsCard
          icon={
            <StaticImage
              src={'../../assets/videos.svg'}
              alt="Videos"
              placeholder="blurred"
              width={35}
            />
          }
          titleId="800 000+"
          subtitleId="index.statisticsCards.videos.subtitle"
        />
        <StatisticsCard
          icon={
            <StaticImage
              src={'../../assets/cuts.svg'}
              placeholder="blurred"
              alt="Cuts"
              width={35}
            />
          }
          titleId="15 000 000+"
          subtitleId="index.statisticsCards.cuts.subtitle"
        />
        </div>*/}
    </>
  );
};

export default IndexMain;
