// extracted by mini-css-extract-plugin
export var background = "IndexInfos-module--background--c5e41";
export var container = "IndexInfos-module--container--730cd";
export var containerFlex = "IndexInfos-module--container-flex--eb75e";
export var dotCube = "IndexInfos-module--dotCube--81304";
export var illustration = "IndexInfos-module--illustration--08449";
export var illustrationContainer = "IndexInfos-module--illustrationContainer--f35a1";
export var infosContainer = "IndexInfos-module--infosContainer--7ff9d";
export var lineCube = "IndexInfos-module--lineCube--66dc9";
export var sunShines = "IndexInfos-module--sunShines--24642";
export var textOrange = "IndexInfos-module--text-orange--f4325";