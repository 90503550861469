import React, { useState } from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';

import BlogCard from '../../components/Cards/BlogCard/BlogCard';
import CustomCarousel from '../../components/CustomCarousel/CustomCarousel';
import SecondaryTitle from '../../components/Title/SecondaryTitle/SecondaryTitle';
import ArticlePreviewType from '../../types/ArticlePreview';

import * as css from './IndexBlog.module.scss';

export type IndexBlogProps = {
  blogPosts: { nodes: ArticlePreviewType[] };
};

const IndexBlog = ({ blogPosts }: IndexBlogProps) => {
  const intl = useIntl();
  return (
    <div>
      <div className="container-flex">
        <div className="container">
          <SecondaryTitle
            size="large"
            titleId="blog.mainTitle.title"
            subtitleId="blog.mainTitle.subtitle"
          />
          <div className={css.carouselContainer}>
            <CustomCarousel>
              {blogPosts.nodes
                .filter(function (blogPost) {
                  return blogPost.frontmatter.language === intl.locale;
                })
                .map(blogPost => (
                  <BlogCard
                    key={Math.random()}
                    textId={blogPost.frontmatter.metaDescription}
                    titleId={blogPost.frontmatter.title}
                    subtitleId={blogPost.frontmatter.tags}
                    image={blogPost.frontmatter.thumb.childImageSharp.fluid}
                    link={`/blog/${blogPost.frontmatter.slug}`}
                  />
                ))}
            </CustomCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexBlog;
