// extracted by mini-css-extract-plugin
export var amount = "PriceCard-module--amount--081e6";
export var arrowContainer = "PriceCard-module--arrowContainer--015ac";
export var container = "PriceCard-module--container--15855";
export var containerFlex = "PriceCard-module--container-flex--f3a19";
export var duration = "PriceCard-module--duration--9b260";
export var header = "PriceCard-module--header--a86fc";
export var precentage = "PriceCard-module--precentage--9ef50";
export var previousPrice = "PriceCard-module--previousPrice--e2431";
export var price = "PriceCard-module--price--f1c7e";
export var priceContainer = "PriceCard-module--priceContainer--6fc61";
export var primary = "PriceCard-module--primary--aa4c3";
export var subtitle = "PriceCard-module--subtitle--df983";
export var textContainer = "PriceCard-module--textContainer--3cd34";
export var textOrange = "PriceCard-module--text-orange--9f97f";
export var title = "PriceCard-module--title--c3749";