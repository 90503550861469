import React from 'react';

import * as css from './LineCube.module.scss';

export type LineCubeType = {
  animate?: boolean;
};

const LineCube = ({ animate }: LineCubeType) => {
  return (
    <div
      className={animate ? `${css.container} ${css.animate} ` : css.container}
    >
      <div className={css.line} />
      <div className={css.line} />
      <div className={css.line} />
    </div>
  );
};

export default LineCube;
