// extracted by mini-css-extract-plugin
export var buttons = "GradientBanner-module--buttons--850c4";
export var circleOne = "GradientBanner-module--circleOne--2e624";
export var circleTwo = "GradientBanner-module--circleTwo--8cc04";
export var circlesContainer = "GradientBanner-module--circlesContainer--fba08";
export var circlesOuterContainer = "GradientBanner-module--circlesOuterContainer--fd921";
export var container = "GradientBanner-module--container--1b733";
export var containerFlex = "GradientBanner-module--container-flex--66987";
export var dotCube = "GradientBanner-module--dotCube--9373f";
export var text = "GradientBanner-module--text--15352";
export var textOrange = "GradientBanner-module--text-orange--05427";