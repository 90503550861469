import React from 'react';

import QaCard from '../../components/Cards/QaCard/QaCard';
import SecondaryTitle from '../../components/Title/SecondaryTitle/SecondaryTitle';

import * as css from './IndexFaq.module.scss';

const IndexFaq = () => {
  return (
    <div>
      <div className="container-flex">
        <div className="container">
          <SecondaryTitle
            size="large"
            titleId="index.QnA.secondaryTitle.title"
            subtitleId="index.QnA.secondaryTitle.subtitle"
          />
          <div className={css.qaContainer}>
            <QaCard
              answerId="index.QnA.items.twoComputers.answer"
              questionId="index.QnA.items.twoComputers.question"
            />
            <QaCard
              answerId="index.QnA.items.compatibility.answer"
              questionId="index.QnA.items.compatibility.question"
            />
            <QaCard
              answerId="index.QnA.items.cancel.answer"
              questionId="index.QnA.items.cancel.question"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexFaq;
